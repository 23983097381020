.infinite-scroll-component {
    overflow: hidden !important; 
}

.modal.fade .modal-dialog {
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
  }
  
  .modal.show .modal-dialog {
    transform: translateY(0);
  }

  .bottom-modal .modal-dialog {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    max-width: none;
  }