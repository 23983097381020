.NewNavLinkColorActive{
    color: #084298;
    font-size: 0.8rem;
    font-weight: 400;
}
.NewNavLinkColor{
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 200;


    
}
