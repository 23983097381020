.parentDisableCommon{
    position:fixed;
    left: 0;
    top: 0;
    background: rgb(255, 255, 255);
    z-index: 998;
    height: 100%;
    width: 100%;
  }

.parentDisableFull{
    position:fixed;
    left: 0;
    top: 0;
    background: #0d6efd;
    z-index: 1500;
    height: 100%;
    width: 100%;
  }
  .parentDisableLight
  {
    position:fixed;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.849);
    z-index: 998;
    height: 100%;
    width: 100%;
  }