input[type="text"]::-webkit-input-placeholder {
    color: #909090;
  }





.form-control-feedback {
    position: absolute;
    padding-left: 0.8rem;
    z-index: 2;
    display: block;
    line-height: 2.375rem;
    pointer-events: none;
    color: #909090;
}