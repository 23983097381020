.formLabelNew
    {
     font-weight:600;
     font-size:0.8em
    
    }

.formInputTxt
    {
     font-weight:400;
     font-size:0.8rem
    
    }
.formErrorNew
    {
     font-weight:300;
     font-size:0.8em;
     color: red;
    
    }


input[type="text"]::-webkit-input-placeholder {
        color: #aaa9a9;
        font-size: 0.8rem;
      }
input[type="email"]::-webkit-input-placeholder {
        color: #aaa9a9;
        font-size: 0.8rem;
      }
       
select {
        color: rgb(224, 0, 0); 
  

   }